.App {
  text-align: center;
  height: 100%;
}

button {
  border: 0;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

button:disabled {
  cursor: default;
}

body {
  height: 100%;
  max-width: 100% !important;
}

html {
  height: 100%;
}

#root {
  height: 100%;
  background: #fff;
}

.swal2-container {
  z-index: 9999 !important;
}

.PromotionConfirmButton {
  color: #fff !important;
  border-radius: 40px !important;
  padding: 15px 40px !important;
  font-weight: bold !important;
}

.PromotionConfirmButton:focus {
  outline: none;
  box-shadow: none !important;
}

.PromotionCancelButton {
  color: #fff !important;
  border-radius: 40px !important;
  padding: 15px 40px !important;
  font-weight: bold !important;
}

.PromotionCancelButton:focus {
  outline: none;
  box-shadow: none !important;
}
